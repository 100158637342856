<template>
  <b-card
      v-if="showFilterCard"
      no-body
  >
    <div
        v-if="!enable || isLoading"
        class="disabled-card"
    />
    <b-card-header class="pb-50">
      <h5>
        {{ this.$i18n.t('filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            class="mb-md-0 mb-2"
            cols="12"
            md="10"
        >
          <b-row>
            <b-col
                v-if="isActiveSuppliersFilter"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.supplier') }}</label>
              <v-select
                  v-model="filter.supplier"
                  :dir="direction"
                  :disabled="Boolean($route.params.sku)"
                  :options="suppliersOptions"
                  class="w-100"
                  multiple
              />
            </b-col>
            <b-col
                v-if="showFilter.season"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.season') }}</label>
              <v-select
                  v-model="filter.season"
                  :dir="direction"
                  :disabled="Boolean($route.params.sku)"
                  :options="seasonsOptions"
                  class="w-100"
                  multiple
              />
            </b-col>

            <b-col
                v-if="showFilter.startDate"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.startDate') }}</label>
              <flat-pickr
                  v-model="filter.startDate"
                  :config="startDatePickerConfig"
                  class="form-control"
              />
            </b-col>
            <b-col
                v-if="showFilter.endDate"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.endDate') }}</label>
              <flat-pickr
                  v-model="filter.endDate"
                  :config="endDatePickerConfig"
                  class="form-control"
              />
            </b-col>
            <b-col
                v-if="showFilter.isAg"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.isAg') }}</label>
              <v-select
                  v-model="ag"
                  :dir="direction"
                  :disabled="Boolean($route.params.sku)"
                  :options="isAgOptions"
                  :reduce="(val) => val.value"
                  class="w-100"
                  label="title"
                  @input="(val) => updateIsAg(val)"
              />
            </b-col>
            <b-col
                v-if="showFilter.platforms"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.platforms') }}</label>
              <v-select
                  v-model="filter.platforms"
                  :dir="direction"
                  :options="platformsOptions"
                  class="w-100"
                  multiple
              />
            </b-col>
            <b-col
                v-if="showFilter.years"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.years') }}</label>
              <v-select
                  v-model="filter.years"
                  :dir="direction"
                  :options="listYears()"
                  class="w-100"
                  multiple
              />
            </b-col>
            <b-col
                v-if="showFilter.platformGroups"
                class="mb-md-0 mb-2"
                cols="12"
                md="2"
            >
              <label>{{ this.$i18n.t('reports.filter.platform_groups') }}</label>
              <v-select
                  v-model="filter.platformGroups"
                  :dir="direction"
                  :options="allPlatformGroupsOptions"
                  :reduce="val => val.id"
                  class="w-100"
                  label="name"
                  multiple
              />
            </b-col>
            <b-col
                v-if="showFilter.groupByPlatformGroup"
                class="mb-md-0 mb-2 mt-2"
                cols="12"
                md="12"
            >
              <b-form-checkbox
                  v-model="filter.groupByPlatformGroup"
                  name="checkbox-1"
              >
                <label>{{ this.$i18n.t('reports.filter.groupByPlatformGroup') }} </label>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col
            class="text-right mb-md-0 mb-2"
            cols="12"
            md="2"
        >
          <b-button
              class="m-1"
              variant="primary"
              @click="handleUpdateFilter()"
          >
            {{ $i18n.t('filtering') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {flatPickr},
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    // Suppliers
    showSuppliersFilter: {
      type: Boolean,
      default: false,
    },
    suppliers: {
      type: Array,
      default() {
        return null
      },
    },
    disableDefaultSupplier: {
      type: Boolean,
      default: false,
    },
    // Seasons
    showSeasonsFilter: {
      type: Boolean,
      default: false,
    },
    seasons: {
      type: Array,
      default() {
        return null
      },
    },
    // Date
    showDateFilter: {
      type: Boolean,
      default: false,
    },
    minAvailableDate: {
      type: String,
      default: null,
    },
    maxAvailableDate: {
      type: String,
      default: null,
    },
    // IsAg
    showIsAgFilter: {
      type: Boolean,
      default: false,
    },
    isAg: {
      type: Array,
      default() {
        return null
      },
    },
    showPlatformsFilter: {
      type: Boolean,
      default: false,
    },
    platforms: {
      type: Array,
      default() {
        return null
      },
    },
    showYearFilter: {
      type: Boolean,
      default: false,
    },
    years: {
      type: Array,
      default() {
        return null
      },
    },
    showPlatformGroupsFilter: {
      type: Boolean,
      default: false,
    },
    platformGroups: {
      type: Array,
      default() {
        return null
      },
    },
    showGroupByPlatformGroup: {
      type: Boolean,
      default: false,
    },
    groupByPlatformGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isAgOptions: [],
      suppliersOptions: [],
      seasonsOptions: [],
      platformsOptions: [],
      platformGroupsOptions: [],
      minDate: null,
      maxDate: null,
      // Filter
      filter: {
        supplier: null,
        season: null,
        startDate: null,
        endDate: null,
        isAg: null,
        platforms: null,
        years: null,
        platformGroups: null,
        groupByPlatformGroup: false,
      },
      ag: null,
    }
  },
  computed: {
    showFilter() {
      return {
        supplier: this.showSuppliersFilter,
        season: this.showSeasonsFilter,
        startDate: this.showDateFilter,
        endDate: this.showDateFilter,
        isAg: this.showIsAgFilter,
        platforms: this.showPlatformsFilter,
        years: this.showYearFilter,
        platformGroups: this.showPlatformGroupsFilter,
        groupByPlatformGroup: this.showGroupByPlatformGroup,
      }
    },
    showFilterCard() {
      return Object.values(this.showFilter).some(val => val === true);
    },
    isActiveSuppliersFilter() {
      return this.showFilter.supplier && this.suppliersOptions && this.suppliersOptions.length !== 1
    },
    startDatePickerConfig() {
      return {
        mode: 'single',
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: {
          firstDayOfWeek: 1
        },
        onChange: (selectedDates, dateStr) => {
          if (dateStr !== "") {
            return;
          }
          this.changeDateValue('startDate', dateStr);
        },
        onValueUpdate: (selectedDates, dateStr) => {
          this.changeDateValue('startDate', dateStr);
        }
      }
    },
    endDatePickerConfig() {
      return {
        mode: 'single',
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: {
          firstDayOfWeek: 1
        },
        onChange: (selectedDates, dateStr) => {
          if (dateStr !== "") {
            return;
          }
          this.changeDateValue('endDate', dateStr);
        },
        onValueUpdate: (selectedDates, dateStr) => {
          this.changeDateValue('endDate', dateStr);
        }
      }
    },
    direction() {
      return this.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    },
    allPlatformGroupsOptions() {
      return [...this.platformGroupsOptions, {
        id: 'other',
        name: this.$i18n.t(`other`),
      }]
    }
  },
  mounted() {
    if (this.auth().sales_permission !== null) {
      this.showFilter.isAg = false
    }
    this.getUtilsFilter()
  },
  methods: {
    getUtilsFilter() {
      this.isLoading = true
      this.$http.get('utils/filter-reports')
          .then(res => {
            const response = res.data.data
            this.suppliersOptions = this.suppliers ? this.suppliers : response.suppliers
            this.seasonsOptions = this.seasons ? this.seasons : response.seasons
            this.isAgOptions = this.isAg ? this.isAg : response.isAgOptions
            this.minDate = this.minAvailableDate ? this.minAvailableDate : response.minDate
            this.maxDate = this.maxAvailableDate ? this.maxAvailableDate : response.maxDate
            this.platformsOptions = this.platforms ? this.platforms : response.platforms
            this.platformGroupsOptions = this.platformGroups ? this.platformGroups : response.platformGroups
            this.isLoading = false
            this.initFilter()
          })
    },
    initFilter() {
      if (Object.keys(this.$route.query).length === 0 && this.suppliersOptions.length > 5 && this.showFilter.supplier && !this.disableDefaultSupplier) {
        this.filter.supplier = this.suppliersOptions[0]
        this.updateQueryString();
      }
      this.updateFilter(this.$route.query)
      this.fixParams()
      this.syncFilter()
      this.$emit('initFilter', true)
    },
    handleUpdateFilter() {
      this.syncFilter()
      this.updateQueryString();
    },
    updateIsAg(newVal) {
      this.filter.isAg = newVal
    },
    syncFilter() {
      const updatedFilter = this.fixFilter(this.filter)
      this.$emit(`update:filter`, updatedFilter)
    },
    updateFilter(newVal) {
      Object.keys(this.filter).forEach(key => {
        this.filter[key] = (newVal[key] && this.showFilter[key]) ? newVal[key] : null
      })
    },
    updateQueryString() {
      const updatedFilter = this.fixFilter(this.filter)
      this.$router.replace({query: updatedFilter})
    },
    fixFilter(filter) {
      return Object.keys(filter).reduce((newObject, key) => {
        const filterValue = filter[key]?.join?.(',') ?? filter[key];
        if (this.showFilter[key] && filterValue) {
          // eslint-disable-next-line no-param-reassign
          newObject[key] = filterValue;
        }
        return newObject;
      }, {});
    },
    fixParams() {
      this.fixIsAge()
      this.fixMultiSelect()
      this.fixIdSelect()
    },
    fixIsAge() {
      this.isAgOptions.forEach((elem) => {
        if (elem.value === this.filter.isAg || ((this.filter.isAg) && elem.value === Number(this.filter.isAg))) {
          this.ag = elem
        }
      })
    },
    fixMultiSelect() {
      const filterMultiSelect = ['season', 'supplier', 'years', 'platforms', 'platformGroups']
      filterMultiSelect.forEach((key) => {
        if (this.filter[key]) {
          this.filter[key] = this.filter[key].split(',')
        }
      })
    },
    fixIdSelect() {
      const values = this.filter.platformGroups || []
      this.filter.platformGroups = []
      values.forEach(val => {
        let value = val
        if (val !== "other") value = Number(val)
        this.filter.platformGroups.push(value)
      })
    },
    changeDateValue(key, dateStr) {
      const newDate = (dateStr === "") ? null : dateStr
      if (this.filter[key] !== newDate) {
        this.filter[key] = newDate
      }
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.disabled-card {
  background-color: #ffffff70;
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.vs--disabled .vs__selected {
  color: #6e6b7b !important;
}
</style>
